var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-button mb-1"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v(" 增加每周信息采集 ")])],1)]},proxy:true}])},[_c('el-table',{staticClass:" mt-1",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"realName","label":"姓名"}}),_c('el-table-column',{attrs:{"prop":"gradeName","label":"学年"}}),_c('el-table-column',{attrs:{"prop":"term","label":"学期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.term ? ("第" + (row.term) + "学期") : '')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"weekNo","label":"周次"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.term ? ("第" + (row.weekNo) + "周") : '')+" ")]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"auditStatus","label":"审核情况"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.auditStatus === 3)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("审核通过")]):(row.auditStatus === 2)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("审核不通过")]):(row.auditStatus === 1)?_c('el-tag',[_vm._v("未审核")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"auditMessage","label":"审核反馈"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"mainWorkContents","label":"本周主要工作内容"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"selfDiagnosisOpinion","label":"自我诊断意见"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"improvement","label":"改进建议"}}),_c('el-table-column',{attrs:{"prop":"type","label":"类型"}}),_c('el-table-column',{attrs:{"label":"操作","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showDialog(row.id)}}},[_vm._v(" 编辑 ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(_vm.delWeeklyReportByUserId, row.id, ((row.gradeName) + " 第" + (row.term) + "学期 诊改信息"))}}},[_vm._v(" 删除 ")])]}}])})],1),(_vm.visibleDialog)?_c('dialog-add-weekly-report',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }