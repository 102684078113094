<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <div class="header-button mb-1">
                <el-button @click="showDialog()" type="primary" size="small">
                    增加每周信息采集
                </el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column prop="realName" label="姓名"></el-table-column>
            <el-table-column prop="gradeName" label="学年"></el-table-column>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term}学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="weekNo" label="周次">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.weekNo}周` : '' }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="auditStatus" label="审核情况">
                <template v-slot="{ row }">
                    <el-tag v-if="row.auditStatus === 3" type="success">审核通过</el-tag>
                    <el-tag v-else-if="row.auditStatus === 2" type="danger">审核不通过</el-tag>
                    <el-tag v-else-if="row.auditStatus === 1">未审核</el-tag>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="auditMessage" label="审核反馈"></el-table-column>
            <el-table-column show-overflow-tooltip prop="mainWorkContents" label="本周主要工作内容"></el-table-column>
            <el-table-column show-overflow-tooltip prop="selfDiagnosisOpinion" label="自我诊断意见"></el-table-column>
            <el-table-column show-overflow-tooltip prop="improvement" label="改进建议"></el-table-column>
            <el-table-column prop="type" label="类型"></el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        编辑
                    </el-button>
                    <el-button type="text"
                        @click="del(delWeeklyReportByUserId, row.id, `${row.gradeName} 第${row.term}学期 诊改信息`)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <dialog-add-weekly-report ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog'
            @on-close='renderTable' />
    </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'

import { delWeeklyReportByUserId, pageWeeklyReportByCurUser } from '@/api/infoCollect/weeklyInfoCollect-api.js'
import DialogAddWeeklyReport from './components/DialogAddWeeklyReport.vue'

export default {
    components: { DialogAddWeeklyReport },
    mixins: [tableView],
    data() {
        return {
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                currentWorkSummary: '',
                currentSignificantEvents: '',
                nextWorkPlan: '',
                selfDiagnosisOpinions: '',
                improvement: ''
            }
        }
    },
    mounted() {
        this.renderTable()
    },
    methods: {
        delWeeklyReportByUserId,
        async renderTable(pageNum) {
            await this.getTableData(pageWeeklyReportByCurUser, pageNum)
        }
    }
}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
